import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Content, { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";
import logoSolidWhite from "../img/signature-multi-white.svg";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export const DefaultPageTemplate = ({
  heading,
  content,
  contentComponent,
  featuredimage,
  featuredimagealt,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="container mt-2 md:mt-8">
      <div className="columns">
        <div className="w-full lg:w-10/12">
          {featuredimage ? (
            <figure className="w-full md:w-11/12 lg:w-10/12 mb-4 md:mb-8">
              <PreviewCompatibleImage
                imageInfo={{
                  image: featuredimage,
                  imageStyle: {
                    objectFit: "contain",
                    objectPosition: "100% 100%",
                  },
                  alt: featuredimagealt || "",
                }}
              />
            </figure>
          ) : null}

          <h1
            dangerouslySetInnerHTML={{ __html: heading }}
            className="font-heading text-3xl lg:text-4xl xl:text-5xl"
          ></h1>

          <PostContent
            content={content}
            className="mt-8 prose lg:prose-lg max-w-none accent-royal"
          />
        </div>
      </div>
    </section>
  );
};

const DefaultPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout
      headerClassName="bg-accent"
      headerContent={<span />}
      navbarLogo={logoSolidWhite}
      className="accent-navy"
      headerTitle="Not found"
    >
      <Helmet titleTemplate="%s - ScriptDrop">
        <title>{`${post.frontmatter.title}`}</title>
      </Helmet>

      <DefaultPageTemplate
        heading={post.frontmatter.heading}
        content={post.html}
        contentComponent={HTMLContent}
        featuredimage={post.frontmatter.featuredimage}
        featuredimagealt={post.frontmatter.featuredimagealt}
      />
    </Layout>
  );
};

export default DefaultPage;

export const pageQuery = graphql`
  query pageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        featuredimagealt
        featuredimage {
          childImageSharp {
            fluid(
              maxWidth: 800
              quality: 85
              traceSVG: { background: "transparent", color: "#D4E6FE" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;
